import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { themeStyles } from '../utils/theme'
import { rhythm } from "../utils/typography"
import colors from '../utils/colors'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author} = data.site.siteMetadata
        const imgAvatar = data.avatar.childImageSharp.fixed
        return (
          <div css={[themeStyles.textPadding, {paddingTop: 0, fontSize: rhythm(0.7)}]}>
            <Link to="/pages/about">
              <Img
                fixed={imgAvatar}
                alt={author}
                style={{
                  float: 'left',
                  marginRight: rhythm(0.5),
                  marginBottom: rhythm(0.5),
                  borderRadius: '100%',
                  border: '1px solid ' + colors.lightGrey,
                }}
              />
            </Link>
            <span>
              Written by <Link to="/pages/about" ><strong>{author}</strong></Link> who live in Sonoma, California, USA and frequently elsewhere...
            </span>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(relativePath: { eq: "profile-photo.jpg" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default Bio
