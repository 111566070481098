import React from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'
import { presets } from '../utils/theme'
import colors from '../utils/colors'

import Img from "gatsby-image"

const styles = {
  link: {
    position: 'relative',
    width: '100%',
  },
  photoTitle: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Karla',
    fontSize: rhythm(1.5),
    padding: rhythm(0.5),
    textAlign: 'center',
    textShadow: '1px 1px ' + colors.darkGrey,
    [presets.Large]: {
      fontSize: rhythm(6),
    },
    [presets.Medium]: {
      fontSize: rhythm(4),
    },
  },
  photo: {
    marginBottom: rhythm(1.5)
  }
}


export const Photo = ({ title, imageResponsive, slug }) => {
  const internals = (
    <div css={styles.link}>
      <div css={styles.photoTitle}>
        {title}
      </div>
      {imageResponsive && <Img fluid={imageResponsive} css={styles.photo}/> }
    </div>
  )
  if (slug) {
    return (
      <Link to={slug}>
        {internals}
      </Link>
    )
  }
  return internals
}

export default Photo