import React from 'react'
import { rhythm } from '../utils/typography'
import Img from "gatsby-image"

const TitleDatePhoto = ({ title, date, imageResponsive, hidePhoto }) =>
  <div>
    <h2 css={{fontSize: rhythm(1.5), marginBottom: rhythm(0.1)}}>
      {title}
    </h2>
    <div css={{fontSize: rhythm(0.75), marginBottom: rhythm(0.5)}}>
      {date}
    </div>
    {imageResponsive && !hidePhoto &&
      <Img fluid={imageResponsive} css={{marginBottom: rhythm(0.5)}}/>
    }
  </div>

export default TitleDatePhoto